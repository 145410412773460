import React from "react"
import Hero from "../components/hero"
import PostLink from "../components/post-link"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import {BurgerMenuIconAnimation} from "../constants/layoutStyle"
import BurgerMenu from "../components/burgerMenu";
import TopPageLayout from "../components/topPageLayout";

export default function WorkTypeIndex({ data }) {
  return (
    <>
      <SEO description={`できること「${data.contentfulWorkType.displayString}」でフィルタリングした結果です。`} />
      <TopPageLayout>
          <BurgerMenu iconAnination={BurgerMenuIconAnimation.Visible} />
          <div className="top-main">
          <Hero />
          <main className="main-area">
            <h1 className="work-type-title">
              {data.contentfulWorkType.displayString}
            </h1>
            {data.allContentfulPost.edges.map(edge => (
              <PostLink key={edge.node.slug} post={edge.node} />
            ))}
          </main>
        </div>
      </TopPageLayout>
    </>
  )
}

export const query = graphql`
  query postsFilteredByWorkType($id: String!) {
    allContentfulPost(
      filter: {
        workTypes: { elemMatch: { id: { eq: $id } } }
        node_locale: { eq: "ja" }
      }
      sort: { fields: [publicationDate], order: [DESC] }
    ) {
      edges {
        node {
          title
          indexImage {
            title
            gatsbyImageData
          }
          images {
            title
            gatsbyImageData
          }
          description {
            description
          }
          slug
          updatedAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
          publicationDate
        }
      }
    }
    allContentfulSiteLogos(filter: { node_locale: { eq: "ja" } }) {
      edges {
        node {
          heroImage {
            url
          }
        }
      }
    }
    contentfulWorkType(node_locale: { eq: "ja" }, id: { eq: $id }) {
      id
      title
      slug
      displayString
      node_locale
    }
  }
`
